<template>
  <LSection class="registerDigiQTAccount">
    <template #body>
      <div class="flex justify-center my-4">
        <div v-if="!isSent">
          <form
            @submit.prevent=""
            @keydown.enter.prevent=""
            ref="form"
            class="bg-white px-12 py-10 shadow border rounded max-w-2xl flex flex-col justify-center"
          >
            <h1 class="heading1">
              Request a DigiQ-Track Account
              <hr class="gradientLine" />
            </h1>
            <StatusMessage operation="REQUEST_DIGIQT_ACCOUNT"></StatusMessage>
            <StatusMessage operation="LIST_ASSETS"></StatusMessage>

            <div class="flex flex-col justify-center align-center">
              <div v-if="!isSubmitted" class="flex flex-col">
                <p class="py-2">
                  Create a DigiQ-Track account by filling in below details. You
                  will receive an activation email, which may be in your spam
                  folder.
                </p>
                <div class="w-full md:w-1/2 lg:w-1/3 mb-2">
                  <label for="firstName">First Name*</label>
                  <TextInput
                    autofocus
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    :value="account.firstName"
                    @change="(value) => updateAccount('firstName', value, true)"
                    @input="(value) => updateAccount('firstName', value, true)"
                  ></TextInput>
                  <LFieldError :message="inputError.firstName" class="w-full">
                  </LFieldError>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 mb-2">
                  <label for="lastName">Last Name*</label>
                  <TextInput
                    autofocus
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    :value="account.lastName"
                    @change="(value) => updateAccount('lastName', value, true)"
                    @input="(value) => updateAccount('lastName', value, true)"
                  ></TextInput>
                  <LFieldError :message="inputError.lastName" class="w-full">
                  </LFieldError>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 mb-2">
                  <label for="title">Academic Title</label>
                  <TextInput
                    autofocus
                    id="title"
                    name="title"
                    placeholder="Your Academic Title"
                    :value="account.title"
                    @change="(value) => updateAccount('title', value)"
                  ></TextInput>
                  <LFieldError :message="inputError.name" class="w-full">
                  </LFieldError>
                </div>
                <div class="flex justify-between mb-2">
                  <div class="">
                    <label for="university">University*</label>
                    <DropdownSingle
                      :title="account.university || 'Select University'"
                      :items="availableUnis.sort()"
                      :tabindex="3"
                      class="w-52"
                      id="university"
                      name="university"
                      placeholder="Select University"
                      @select="
                        (value) => updateAccount('university', value, true)
                      "
                    />
                    <LFieldError
                      :message="inputError.university"
                      class="w-full"
                    >
                    </LFieldError>
                  </div>
                  <div v-if="!role" class="">
                    <label for="role">Role*</label>
                    <DropdownSingle
                      :title="account.role || 'Select Your Role'"
                      :items="roles"
                      :tabIndex="5"
                      id="role"
                      name="role"
                      placeholder="Select Your Role"
                      @select="(value) => updateAccount('role', value, true)"
                    />
                    <LFieldError :message="inputError.role" class="w-full">
                    </LFieldError>
                  </div>
                </div>
                <div
                  v-if="showStudentFields"
                  class="w-full md:w-1/2 lg:w-1/3 mb-2"
                >
                  <label for="network">Network*</label>
                  <!-- TODO need the right items -->
                  <DropdownSingle
                      :title="account.network || 'Select Your Network'"
                      :items="networks"
                      :tabIndex="5"
                      id="network"
                      name="network"
                      placeholder="Select Your Network"
                      @select="(value) => updateAccount('network', networkLabelToValue(value), true)"
                  /><LFieldError :message="inputError.network" class="w-full">
                </LFieldError>

                  <label for="programme">Study Programme*</label>
                  <TextInput
                    autofocus
                    id="programme"
                    name="programme"
                    placeholder="Programme degree, name, and year"
                    @change="(value) => updateAccount('programme', value, true)"
                    @input="(value) => updateAccount('programme', value, true)"
                  ></TextInput>
                  <LFieldError :message="inputError.programme" class="w-full">
                  </LFieldError>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 mb-2">
                  <label for="email">Email address*</label>
                  <TextInput
                    id="email"
                    name="email"
                    :value="account.email"
                    placeholder="Email address"
                    @input="(value) => updateAccount('email', value, true)"
                    @change="(value) => updateAccount('email', value, true)"
                  ></TextInput>
                  <LFieldError :message="inputError.email"></LFieldError>
                </div>
                <div class="mb-2 text-sm">Fields with * are required</div>
              </div>

              <div v-if="isSent">Please wait...</div>

              <div
                v-if="isSubmitted"
                class="items-center justify-start flex flex-row"
              >
                <Button
                  type="submit"
                  :tabindex="13"
                  :is-primary="true"
                  @click="$router.push('/track')"
                >
                  <template #right>Go to Home</template>
                </Button>
              </div>
              <div
                v-if="!isSubmitted"
                class="items-center justify-between flex flex-row"
              >
                <Button
                  class="button secondary"
                  @click="$router.go(-1)"
                  type="submit"
                >
                  <template #right>Cancel</template>
                </Button>
                <Button
                  type="submit"
                  :is-primary="true"
                  :class="
                    canSubmit && !isSubmitted
                      ? ''
                      : 'opacity-50 pointer-events-none'
                  "
                  @click="onSubmit"
                  @keydown.enter="onSubmit"
                >
                  <template #right>Register</template>
                </Button>
              </div>
            </div>
          </form>
        </div>
        <div v-if="isSent">
          <h1 class="heading1">
            Thanks for requesting a DigiQT Account
            <hr class="gradientLine" />
          </h1>
          <StatusMessage operation="REQUEST_DIGIQT_ACCOUNT"></StatusMessage>
        </div>
      </div>
    </template>
  </LSection>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
  watchPostEffect,
} from "vue";
import LSection from "@/components/layout/LSection.vue";
import Button from "@/components/forms/Button";
import { isEmailAddress } from "@/lib/typeHelpers/stringFunctions/isEmailAddress";
import LFieldError from "@/components/layout/LFieldError";
import { useStore } from "vuex";
import { LIST_ASSETS, REQUEST_DIGIQT_ACCOUNT } from "@/store/operations";
import StatusMessage from "@/components/status/StatusMessage.vue";
import { useStatusMessage } from "@/composables/useStatusMessage";
import TextInput from "@/components/forms/TextInput.vue";
import DropdownSingle from "@/components/forms/DropdownSingle.vue";
import { state } from "@/store/store";
import { digiQRoles } from "@/lib/digiQ/digiQRoles";
import {digiQNetworks} from "@/lib/digiQ/digiQNetworks";

export default {
  name: "RegisterDigiQTAccount",
  components: {
    DropdownSingle,
    TextInput,
    Button,
    LFieldError,
    LSection,
    StatusMessage,
  },
  props: {
    role: {
      type: String,
      default: "",
    }
  },
  setup(props) {
    const store = useStore();
    const form = ref(null);
    const altKey = ref(false);
    const actionError = ref("");
    // isSent is used to show a different message after the user has submitted the form
    const isSent = ref(false);
    const isSubmitted = ref(false);
    const showStudentFields = computed(
      () =>
        props.role?.toLowerCase() === "student" ||
        account.role?.toLowerCase() === "student"
    );
    const showTeacherFields = ref(false);
    const inputError = reactive({});

    const isValidAccount = computed(
      () =>
        !isSent.value &&
        account.firstName &&
        account.lastName &&
        account.university &&
        account.email &&
        // account.country &&
        account.role &&
        (account.role === "Student"
          ? (account.programme && account.network)
          : // account.role === 'teacher' ?
            //     account.coursesTeaching :
            true) &&
        Object.values(inputError).every((msg) => msg === null)
    );

    const canSubmit = computed(() => isValidAccount.value);
    const availableUnis = ref([]);
    const universities = computed(() =>
      Object.values(state.assets.University ?? []).map(
        (item) => item.title ?? item.name
      )
    );
    store.dispatch(LIST_ASSETS, { dataType: "University" });

    watch(universities, (v) => {
      availableUnis.value = v.filter((item) => !item.startsWith("_"));
    });
    watch(altKey, (v) => {
      if (v) {
        availableUnis.value = universities.value;
      } else {
        availableUnis.value = universities.value.filter(
          (item) => !item.startsWith("_")
        );
      }
    });

    const networkLabelToValue = (value) => digiQNetworks.find((r) => r.label === value).value

    const account = reactive({
      title: "",
      firstName: null,
      lastName: null,
      university: null,
      // country: null,
      role: props.role || null,
      network: null,
      programme: "",
      // coursesTeaching: '',
      email: null,
    });

    const { setMessage } = useStatusMessage(REQUEST_DIGIQT_ACCOUNT);

    const updateAccount = (property, value, required) => {
      if (value) value = value.trim();
      Object.assign(account, { [property]: value });
      // console.log(93, showStudentFields.value, property, value)
      if (required && !value) {
        inputError[property] = "This field is required";
      } else {
        inputError[property] = null;
      }
    };

    watchPostEffect(() => {
      const { email } = account;

      inputError.email =
        email && !isEmailAddress(email) ? "Invalid email address" : null;

      // todo: do not remove this log, hack for resolving digestion race condition
      console.log(3, email, email && !isEmailAddress(email), inputError.email);
    });

    onMounted(() => {
      window.scroll(0, 0);
      window.addEventListener("keydown", (e) => {
        if (e.key === "Alt") altKey.value = true;
      });
      window.addEventListener("keyup", (e) => {
        if (e.key === "Alt") altKey.value = false;
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener("keydown", (e) => {
        if (e.key === "Alt") altKey.value = true;
      });
      window.removeEventListener("keyup", (e) => {
        if (e.key === "Alt") altKey.value = false;
      });
    });

    const submitUserAccountRequest = () => {
      if (!isValidAccount.value) return;

      actionError.value = "";

      const options = {
        ...account,
        // lookup role name
        role: digiQRoles.find((r) => r.label === account.role).value,
        // lookup the repoId for the university
        universityRepoId: Object.values(state.assets?.University).find(
          (i) => i.name === account.university
        ).repoId,
      };
      delete options.university;

      console.log("Requesting user account", options);
      store
        .dispatch(REQUEST_DIGIQT_ACCOUNT, options)
        .then((result) => {
          isSubmitted.value = true;
          return result;
        })
        .catch((err) => {
          console.log("Error requesting user account", err);
          setMessage({ isSuccess: false, body: err.message });
        })
        .finally(() => {
          isSent.value = false;
        });

      // reset form
      Object.keys(options).forEach((key) => delete options[key]);
      isSent.value = true;
    };

    const onSubmit = () => submitUserAccountRequest();

    return {
      account,
      actionError,
      altKey,
      canSubmit,
      // countries,
      availableUnis,
      inputError,
      isSent,
      isSubmitted,
      isValidAccount,
      form,
      onSubmit,
      roles: digiQRoles.map((r) => r.label),
      networks: digiQNetworks.map((r) => r.label),
      networkLabelToValue,
      showStudentFields,
      showTeacherFields,
      updateAccount,
    };
  },
};
</script>

<style scoped></style>
