<template>
  <div class="digiQTrackMenuBar h-fit bg-gradient-to-r">
    <LSection>
      <template #body>
        <nav
          :key="showMenu"
          class="menuBar py-2 k1:h-20 h-fit text-gray-900 font-dark text-lg dark:bg-gray-400"
        >
          <div
            class="relative overflow-hidden flex flex-wrap justify-between items-center h6:mt-0 z-50"
          >
            <!--            <span-->
            <!--                class="-->
            <!--                    banderole-->
            <!--                    absolute-->
            <!--                    text-center-->
            <!--                    top-4 -left-24-->
            <!--                    w-60-->
            <!--                    p-1-->
            <!--                    z-20-->
            <!--                "-->
            <!--                style="background: #58a; border: 5px solid #fff; color: white; transform: rotate(-45deg);"-->
            <!--            >TRACK</span>-->
            <a href="/" class="flex">
              <img
                src="../assets/images/digiqLogo.png"
                class="h-10 h6:h-12 k1:h-16"
              />
            </a>
            <div class="hidden k1:visible k1:flex k1:gap-4 text-xl">
              <router-link
                to="/track"
                :class="{ 'text-black font-bold': $route.path === '/track' }"
                >Home
              </router-link>
              <router-link
                to="/track/info"
                :class="{ 'text-black font-bold': $route.name === 'trackInfo' }"
                >Info
              </router-link>
              <router-link
                to="/track/database"
                :class="{
                  'text-black font-bold': $route.name === 'trackDatabase',
                }"
                >Database
              </router-link>
              <router-link
                v-if="user"
                to="/track/dashboard"
                :class="{ 'text-black font-bold': $route.name === 'dashboard' }"
                >Dashboard
              </router-link>
            </div>
            <div class="k1:ml-8 h6:gap-2 gap-0 items-center text-xl flex k1:gap-4">
              <button
                v-if="!isLoggedIn"
                class="button main"
                @click="$router.push({ query: { modal: 'login' } })"
              >
                Log In
              </button>
              <div v-else-if="account">
                <div class="flex text-xl gap-2 items-center">
                  <div>Welcome {{ splitName }}</div>

                  <button class="hidden h6:block button alt" @click="onLogout">
                    Log Out
                  </button>
                </div>
              </div>
              <button
                type="button"
                class="bg-transparent inline-flex items-center p-2 ml-0 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 k1:hidden"
                @click="showMenu = !showMenu"
              >
                <span class="sr-only">Open navigation menu</span>
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              v-if="showMenu"
              id="navbar-default"
              class="w-full k1:hidden k1:w-auto"
              :class="{ hidden: !showMenu }"
              @click="() => (showMenu = false)"
            >
              <div
                class="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-200 md:flex-row k1:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
              >
                <router-link
                  to="/track"
                  class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  :class="{
                    'text-white bg-blue-700 hover:bg-gray-600':
                      $route.name === 'home',
                    'hover:bg-gray-200 hover:text-gray-700':
                      $route.name !== 'home',
                  }"
                >
                  Home
                </router-link>

                <router-link
                  to="/track/info"
                  class="block py-2 pr-4 pl-3 text-gray-700 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  :class="{
                    'text-white bg-blue-700 hover:bg-gray-600':
                      $route.name === 'info',
                    'hover:bg-gray-200 hover:text-gray-700':
                      $route.name !== 'info',
                  }"
                >
                  Info
                </router-link>

                <router-link
                  to="/track/database"
                  class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  :class="{
                    'text-white bg-blue-700 hover:bg-gray-600':
                      $route.name === 'database',
                    'hover:bg-gray-200 hover:text-gray-700':
                      $route.name !== 'database',
                  }"
                >
                  Database
                </router-link>

                <router-link
                  to="/track/dashboard"
                  class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  :class="{
                    'text-white bg-blue-700 hover:bg-gray-600':
                      $route.name === 'dashboard',
                    'hover:bg-gray-200 hover:text-gray-700':
                      $route.name !== 'dashboard',
                  }"
                >
                  Dashboard
                </router-link>

                <div
                  @click="onLogout"
                  v-if="account && checkScreenWidth"
                  class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        </nav>
      </template>
    </LSection>
  </div>
</template>
<script>
import LSection from "@/components/layout/LSection.vue";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useUserPermissions } from "@/composables/useUserPermissions";
import { useStore } from "vuex";
import { LOGOUT } from "@/store/operations";
import { capitalize } from "@/lib/typeHelpers";

export default {
  components: {
    LSection,
  },
  name: "DigiQTrackMenuBar",
  setup() {
    const { isLoggedIn, account, user } = useUserPermissions();
    const showMenu = ref(false);
    const store = useStore();
    const onLogout = () => {
      store.dispatch(LOGOUT);
    };
    // if the screen width is less than 1000px, show the menu
    const checkScreenWidth = () => window.innerWidth < 1000;

    // Add closeMenu method
    const closeMenu = () => (showMenu.value = false);

    // Split first word of account.name, but only when account.name exists
    const splitName = computed(() => {
      if (account.value?.name)
        return capitalize(account.value?.name?.split(" ")?.[0] ?? "");
      else if (account.value?.firstName) return account.value?.firstName;
      else return "";
    });

    // Add onClickOutside method
    const onClickOutside = (event) => {
      const menuElement = event.target.closest(".menuBar");
      if (!menuElement) {
        closeMenu();
      }
    };

    // Add and remove the onClickOutside listener
    onMounted(() => document.addEventListener("click", onClickOutside));
    onBeforeUnmount(() =>
      document.removeEventListener("click", onClickOutside)
    );

    return {
      isLoggedIn,
      onLogout,
      showMenu,
      account,
      splitName,
      user,
      checkScreenWidth,
    };
  },
};
</script>

<style scoped></style>
