<script>
import {computed} from "vue";
import {useStore} from "vuex";
import {useGenericDocument} from "@/composables/useGenericDocument";

export default {
  name: "StudentDetailsCardFields",
  props: {
    repoId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const {document} = useGenericDocument('_Person', props.repoId)

    const enrolledCommitmentIds = computed(() => document.value.roles?.map(r => {
          if (r.endsWith('student')) return r.split('_')[1]
          return null
        })
            .filter(i => i) ?? []
    )

    const enrolledCourses = computed(() =>
        Object.values(store.state.assets?.Course ?? {})
            .filter(doc =>
                enrolledCommitmentIds.value.includes(doc.repoId)
            )
    )
    const enrolledInternships = computed(() =>
        Object.values(store.state.assets?.Internship ?? {})
            .filter(doc =>
                enrolledCommitmentIds.value.includes(doc.repoId)
            )
    )
    const enrolledNetworks = computed(() =>
        Object.values(store.state.assets?.NetworkCommitment ?? {})
            .filter(doc =>
                enrolledCommitmentIds.value.includes(doc.repoId)
            )
    )

    const enrolledPoints = computed(() => {
      let points = 0
      enrolledCourses.value.forEach(course => points += parseInt(course.ects))
      enrolledInternships.value.forEach(internship => points += parseInt(internship.ects))
      enrolledNetworks.value.forEach(network => points += parseInt(network.ects))
      return points
    })

    const totalPoints = computed(() => {
      let points = 0
      Object.values(store.state.assets?.Awards ?? {})
          .forEach(award => points += parseInt(award.ects))
      return points
    })

    return {
      enrolledCommitmentIds,
      enrolledCourses,
      enrolledInternships,
      enrolledNetworks,
      enrolledPoints,
      totalPoints,
    }
  }
}
</script>

<template>
  <div class="studentDetailsCardFields">
    <div
        v-if="enrolledCourses.length"
        class="text-xl font-semibold tracking-tight mb-3"
    >
      Enrolments
      <div
          class="inline"
          v-if="totalPoints"
      >
        ({{ totalPoints }} Total point<span v-if="totalPoints > 1">s</span>)
      </div>
      <div
          v-if="enrolledPoints"
          class="inline"
      >
        ({{ enrolledPoints }} Total point<span v-if="enrolledPoints > 1">s</span>)
      </div>
    </div>

    <div class="flex flex-col gap-1">
      <div
          class="
          course
          relative
          bg-green-400
          border
          border-green-400
          rounded-r-full
          flex justify-center
          py-1 pl-2 pr-10
        "
          v-for="course in enrolledCourses"
          :key="course.repoId"
      >
        <div
            class="
            absolute -top-0 -right-0
            rounded-full
            flex items-center justify-center
            w-8 h-8
            bg-white
            text-green-600
            border-green-400 border-4
      "
        >
          {{ course.ects }}
        </div>
        {{ course.name }}
      </div>
      <div
          class="
          internship
          bg-violet-400
          rounded-tl-full
          rounded-br-full
          px-4 py-1
        "
          v-for="internship in enrolledInternships"
          :key="internship.repoId"
      >
        <div
            class="
            absolute -top-0 -right-0
            rounded-full
            flex items-center justify-center
            w-8 h-8
            bg-white
            text-violet-600
            border-violet-400 border-4
      "
        >
          {{ internship.ects }}
        </div>
        {{ internship.name }}
      </div>
      <div
          class="
          network
          bg-blue-400
          rounded-l-lg
          px-4 py-1
        "
          v-for="network in enrolledNetworks"
          :key="network.repoId"
      >
        <div
            class="
            absolute -top-0 -right-0
            rounded-full
            flex items-center justify-center
            w-8 h-8
            bg-white
            text-blue-600
            border-blue-400 border-4
      "
        >
          {{ network.ects }}
        </div>
        {{ network.name }}
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>